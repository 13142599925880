import { AppButton } from '@components/AppComponents'
import appStore from '@stores/appStore'

import { RobotOutlined } from '@ant-design/icons'
import AppModal from '@components/AppModal'
import { observer } from 'mobx-react'
import { GetAccessToken } from '@services/baseService'

const Chatbot = () => {
  const accessToken = GetAccessToken()
  return (
    <>
      <AppButton
        className="flex items-center fixed z-[1000] bottom-14 right-6"
        onClick={() => appStore.toggleChatbot()}
        // style={{
        //   // background: '#0c4a6e',
        //   color: '#fff',
        // }}
        color="blue"
        title="Chatbot"
        size="small"
      >
        <RobotOutlined />
      </AppButton>
      <AppModal
        title="Kawaconn Chatbot"
        // open={appStore.quickSetting}
        open={appStore.isOpenChatbot}
        width={800}
        onCancel={() => appStore.toggleChatbot()}
        maskClosable
        noFooter
      >
        <div className="h-[600px]">
          <iframe
            src={`https://copilotstudio.microsoft.com/environments/Default-b56356fd-3818-4630-ae85-a98776d8df34/bots/cr546_kawaconnAssistant/webchat?__version__=2&AccessToken${accessToken}&TenantId=${appStore.currentTenant.id}`}
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        </div>
      </AppModal>
    </>
  )
}

export default observer(Chatbot)
