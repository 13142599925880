import { FROM_MODULE_TYPE } from '@utils/constants'
import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/medneg'
const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const generateLink = (mednegId, data) => {
  return ApiRequest.put(`${BaseRoute}/${mednegId}/eoMedNeg/telelink`, data)
}

const sendEmail = (id, { poMedNegId, eoMedNegId, emailType = '' }) => {
  const param1 = poMedNegId ? `poMedNegId=${poMedNegId}` : ''
  const param2 = eoMedNegId ? `eoMedNegId=${eoMedNegId}` : ''
  return ApiRequest.post(`${BaseRoute}/${id}/emails?${param1}&${param2}&emailType=${emailType}`)
}

const Rebook = id => {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`${BaseRoute}/${id}/rebook`)
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function getPORequests(medNegId: string) {
  return ApiRequest.get(`${BaseRoute}/${medNegId}/poRequests`)
}

function createPORequest(mednegId: string, specialistId: string) {
  return ApiRequest.post(`${BaseRoute}/${mednegId}/poRequests/${specialistId}`)
}

function updatePORequest(data = {}) {
  return ApiRequest.put(`${BaseRoute}/poRequests`, data)
}

function deletePORequest(eoRequestId) {
  return ApiRequest.delete(`${BaseRoute}/poRequests/${eoRequestId}`)
}

function getEOMedNegs(medNegId: string) {
  return ApiRequest.get(`${BaseRoute}/${medNegId}/eoMedNegs`)
}

function initCaseFromRequest(mednegId: string, caseId: string, requestType, requestPO) {
  return ApiRequest.post(
    `${BaseRoute}/${mednegId}/fullReport/${caseId}?requestType=${requestType}&requestPO=${requestPO}`,
  )
}

function createEOMedNeg(mednegId: string, specialistId: string) {
  return ApiRequest.post(`${BaseRoute}/${mednegId}/eoMedNegs/${specialistId}`)
}

function updateEOMedNeg(data = {}) {
  return ApiRequest.put(`${BaseRoute}/eoMedNegs`, data)
}

function deleteEOMedNeg(eoRequestId) {
  return ApiRequest.delete(`${BaseRoute}/eoMedNegs/${eoRequestId}`)
}

function checkExistCaseNo(caseNo) {
  if (!caseNo) return null
  caseNo = caseNo?.trim()
  return ApiRequest.get(`${BaseRoute}/checkExistCaseNo`, { params: { caseNo, fromModule: FROM_MODULE_TYPE.IME } })
}


const API = {
  ...baseFunctions,
  generateLink,
  sendEmail,
  Rebook,
  ExportSearchResult,
  getPORequests,
  createPORequest,
  updatePORequest,
  deletePORequest,
  getEOMedNegs,
  createEOMedNeg,
  updateEOMedNeg,
  deleteEOMedNeg,
  initCaseFromRequest,
  checkExistCaseNo,
}

export default API
