import { Input } from 'antd'
import { useMemo } from 'react'
import InputMask from 'react-input-mask'

const PhoneInput = ({ phoneType, ...props }: { phoneType: 'mobile' | 'LandLine'; [key: string]: any }) => {
  const mask = useMemo(() => {
    // if (phoneType === 'LandLine') {
    //   return '9 9999 9999'
    // }

    return '99 9999 9999'
  }, [phoneType])

  return (
    <InputMask mask={mask} maskChar={phoneType === 'LandLine' ? '' : '_'} {...props}>
      {inputProps => <Input {...inputProps} />}
    </InputMask>
  )
}

export default PhoneInput
