import AccountSwitcher from '@components/AccountSwitcher'
import NotificationSystem from '@components/Notification/components/DropDownNotification'
import ViewingUsers from '@components/Notification/realtime-notification/components/ViewingUsers'
import appStore from '@stores/appStore'
import ui from '@stores/dashboard'
import cx from 'classnames'
import { observer } from 'mobx-react'
import ShowSidebarIcon from './ShowSidebarIcon'
import Tabs from './Tabs'

const DashboardHeader = () => {
  const {
    sidebar: { collapsed: sidebarCollapsed },
  } = ui

  return (
    <div
      className={cx(
        'fixed top-0 right-0 left-0 h-[3.75rem] z-[998] transition-all duration-300',
        { 'sm:left-[5rem]': sidebarCollapsed },
        sidebarCollapsed ? 'left-0' : 'left-[350px]',
      )}
    >
      <Tabs />
      <div className={cx('flex absolute top-0 right-0', { 'hidden sm:flex': sidebarCollapsed })}>
        <ViewingUsers />
        {/* <FullscreenIcon /> */}
        {/* <SystemUpdate /> */}
        {appStore.features.RealtimeNotification && <NotificationSystem />}
        <AccountSwitcher />
      </div>
      {sidebarCollapsed && <ShowSidebarIcon />}
    </div>
  )
}

export default observer(DashboardHeader)
