import cx from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import appStore from '@stores/appStore'
import siteLogo from '@assets/images/logo.png'
import siteLogoShort from '@assets/images/logo-short.png'
import dashboardStore from '@stores/dashboard'
import SidebarHeader from './Header'
import SidebarMenus from './SidebarMenu'
import './Sidebar.scss'

function getLogo(mode?) {
  if (mode === 'short') {
    if (appStore?.currentTenant?.extraProperties?.SecondaryLogo)
      return `${process.env.VITE_API_URL}/files/` + appStore?.currentTenant?.extraProperties?.SecondaryLogo

    return siteLogoShort
  } else {
    if (appStore?.currentTenant?.extraProperties?.Logo)
      return `${process.env.VITE_API_URL}/files/` + appStore?.currentTenant?.extraProperties?.Logo

    return siteLogo
  }
}

class DashboardSidebar extends React.Component {
  render() {
    const {
      sidebar: { collapsed, visible },
      toggleSidebarVisible,
    } = dashboardStore

    const tenantProperties = appStore.isHostTenant
      ? appStore.hostTenant?.extraProperties
      : appStore.currentTenant?.extraProperties

    return (
      <React.Fragment>
        <div className={cx('SidebarAutohideBackdrop', { collapsed, visible })} onClick={toggleSidebarVisible} />
        <div
          className={cx('DashboardSidebar text-sidebar-color-text', {
            'visible left-0 transition-all shadow-sm': visible,
            collapsed,
          })}
        >
          <SidebarHeader />
          <div className={cx('DashboardSidebar__site-logo sm:px-4', { 'hidden sm:block': collapsed })}>
            <a
              href={appStore.currentTenant?.extraProperties?.Website || '/'}
              // target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                padding: '10px',
                backgroundColor: appStore.colorLogo.LogoBackgroundColor,
              }}
              title={tenantProperties?.CustomerName}
            >
              {collapsed ? (
                <img
                  src={getLogo('short')}
                  alt="mag-logo"
                  style={{ height: 42, margin: '0 auto', objectFit: 'contain' }}
                />
              ) : (
                <img
                  src={getLogo()}
                  alt="mag-logo"
                  style={{ height: '80px', objectFit: 'contain', margin: '0 auto' }}
                />
              )}
            </a>
          </div>
          <SidebarMenus />
        </div>
        <div className={cx('SidebarAnimationBackdrop', { collapsed, visible })} />
      </React.Fragment>
    )
  }
}
export default observer(DashboardSidebar)
