import appStore from '@stores/appStore'
import { ApiService } from './ApiService'
import { QueryOptions } from './baseService'
import { RECRUITMENT_STATUS_ENUM, SPECIALIST_STATUS_ENUM } from '@utils/constants'

const MAX_RESULT_COUNT = 20

const CaseManager = (
  {
    filterText = '',
    companyId = undefined,
    position = undefined,
    fromModule = undefined,
    objectId = undefined,
    maxResultCount = MAX_RESULT_COUNT,
    tenantId = undefined,
    caseManagerId = undefined,
    isOnlyGetOnboardedOrOnboarding = true,
    companyIds = [],
  } = {},
  options?: QueryOptions,
) => {
  return ApiService.CaseManager.GetAll(
    {
      filterText,
      companyId,
      position,
      fromModule,
      objectId,
      maxResultCount,
      isSelectionList: true,
      isActive: true,
      sorting: 'FullName',
      tenantId,
      caseManagerId,
      isOnlyGetOnboardedOrOnboarding,
      companyIds,
    },
    options,
  )
}

const Centre = ({
  filterText = '',
  specialistId = undefined,
  fromModule = null,
  objectId = null,
  centreOwnershipType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  centreId = undefined,
  getCentreHasSessionOnly = false,
} = {}) => {
  return ApiService.Centre.GetAll({
    filterText,
    specialistId,
    centreOwnershipType,
    fromModule,
    objectId,
    isSelectionList: true,
    maxResultCount,
    isActive: true,
    sorting: 'Name',
    tenantId,
    centreId,
    getCentreHasSessionOnly
  })
}

const Client = ({
  companyId = undefined,
  filterText = '',
  maxResultCount = MAX_RESULT_COUNT,
  clientId = undefined,
} = {}) => {
  return ApiService.Client.GetAll({
    filterText,
    maxResultCount,
    isSelectionList: true,
    companyId,
    isActive: true,
    sorting: 'firstName, lastName',
    clientId,
  })
}

const Company = ({
  filterText = '',
  fromModule = null,
  objectId = null,
  businessType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  companyId = undefined,
  searchExact = false,
  showAsBorrowerInLenders = undefined,
} = {}) => {
  return ApiService.Company.GetAll({
    filterText,
    businessType,
    fromModule,
    objectId,
    maxResultCount,
    isActive: true,
    isSelectionList: true,
    sorting: 'Name',
    tenantId,
    companyId,
    searchExact,
    showAsBorrowerInLenders,
  })
}

const City = () => {
  return ApiService.Country.GetCitiesByCountry(appStore.currentTenant.country.id)
}

const ServiceProvider = ({
  filterText = '',
  fromModule = null,
  objectId = null,
  businessType = undefined,
  maxResultCount = MAX_RESULT_COUNT,
  tenantId = undefined,
  companyId = undefined,
} = {}) => {
  return ApiService.ServiceProvider.GetAll({
    filterText,
    businessType,
    fromModule,
    objectId,
    maxResultCount,
    isActive: true,
    isSelectionList: true,
    sorting: 'Name',
    tenantId,
    companyId,
  })
}

const Specialist = ({
  filterText = '',
  specialtyId = undefined,
  fromModule = undefined,
  objectId = undefined,
  withoutNCI = undefined,
  isMedNeg = undefined,
  typeOfAssessmentsAcceptedAllow = [],
  includeServiceRates = undefined,
  includeCV = undefined,
  isSelectionList = true,
  maxResultCount = MAX_RESULT_COUNT,
  searchByUsedSpecialist = false,
  includeSpecialty = false,
  includeQualification = false,
  specialistId = undefined,
  recruitmentStatus = undefined,
  specialistStatus = undefined,
  isActive = false,
} = {}) => {
  if (isActive) {
    return ApiService.Specialist.GetAll({
      specialistStatus: SPECIALIST_STATUS_ENUM.ACTIVE,
      recruitmentStatus: [RECRUITMENT_STATUS_ENUM.ONBOARDING, RECRUITMENT_STATUS_ENUM.ONBOARDED],
      filterText,
      specialtyId,
      fromModule,
      objectId,
      withoutNCI,
      isMedNeg,
      maxResultCount,
      isSelectionList: isSelectionList,
      sorting: 'FullName',
      typeOfAssessmentsAcceptedAllow,
      includeServiceRates,
      includeCV,
      searchByUsedSpecialist,
      includeSpecialty,
      includeQualification,
      specialistId,
    })
  }
  return ApiService.Specialist.GetAll({
    filterText,
    specialtyId,
    fromModule,
    objectId,
    withoutNCI,
    isMedNeg,
    maxResultCount,
    specialistStatus,
    isSelectionList: isSelectionList,
    sorting: 'FullName',
    typeOfAssessmentsAcceptedAllow,
    includeServiceRates,
    includeCV,
    searchByUsedSpecialist,
    includeSpecialty,
    includeQualification,
    specialistId,
    recruitmentStatus,
  })
}

const ActiveSpecialist = ({
  filterText = '',
  specialtyId = undefined,
  fromModule = undefined,
  objectId = undefined,
  withoutNCI = undefined,
  isMedNeg = undefined,
  typeOfAssessmentsAcceptedAllow = [],
  includeServiceRates = undefined,
  includeCV = undefined,
  isSelectionList = true,
  maxResultCount = MAX_RESULT_COUNT,
  searchByUsedSpecialist = false,
  includeSpecialty = false,
  includeQualification = false,
  specialistId = undefined,
} = {}) => {
  return ApiService.Specialist.GetAll({
    filterText,
    specialtyId,
    fromModule,
    objectId,
    withoutNCI,
    isMedNeg,
    maxResultCount,
    specialistStatus: SPECIALIST_STATUS_ENUM.ACTIVE,
    recruitmentStatus: [RECRUITMENT_STATUS_ENUM.ONBOARDING, RECRUITMENT_STATUS_ENUM.ONBOARDED],
    isSelectionList: isSelectionList,
    sorting: 'FullName',
    typeOfAssessmentsAcceptedAllow,
    includeServiceRates,
    includeCV,
    searchByUsedSpecialist,
    includeSpecialty,
    includeQualification,
    specialistId,
  })
}

const Contractor = (
  {
    filterText = '',
    companyId = undefined,
    fromModule = undefined,
    objectId = undefined,
    type = undefined,
    maxResultCount = MAX_RESULT_COUNT,
  } = {},
  options?: QueryOptions,
) => {
  return ApiService.Contractor.GetAll(
    {
      filterText,
      companyId,
      type,
      maxResultCount,
      isActive: true,
      isSelectionList: true,
      sorting: 'FullName',
      fromModule,
      objectId,
    },
    options,
  )
}

const LookupConfig = ({
  type = undefined,
  parentIds = undefined,
  filterText = '',
  fromModule = undefined,
  tenantId = null,
  isActive = true,
} = {}) => {
  return ApiService.LookupConfig.GetAll({
    filterText,
    maxResultCount: 1000,
    type,
    isActive: isActive,
    parentIds,
    sorting: 'label',
    fromModule: fromModule,
    tenantId: tenantId,
  })
}

const FundingProvider = ({ filterText = '', fromModule = undefined, objectId = undefined } = {}) => {
  return ApiService.FundingProvider.GetAll({
    filterText,
    maxResultCount: MAX_RESULT_COUNT,
    isActive: true,
    isSelectionList: true,
    fromModule: fromModule,
    objectId: objectId,
  })
}

const Borrower = ({ fundingProviderId }) => {
  return ApiService.FundingAgreement.getBorrowerInfo(fundingProviderId)
}

const FundingAgreement = ({
  filterText = '',
  fundingProviderId = undefined,
  borrowerId = undefined,
  maxResultCount = MAX_RESULT_COUNT,
} = {}) => {
  return ApiService.FundingAgreement.GetAll({
    filterText,
    fundingProviderId,
    borrowerId,
    maxResultCount,
    isActive: true,
    isSelectionList: true,
  })
}

const User = ({ filterText = '', roleNames = undefined, maxResultCount = MAX_RESULT_COUNT, 
  isCreatorCaseManager = false } = {}) => {
  return ApiService.User.GetAll({
    filterText,
    roleNames,
    maxResultCount,
    isActive: true,
    isSelectionList: true,
    sorting: 'Name',
    isCreatorCaseManager,
  })
}

const Staff = ({ filterText = '', isGetBSOFromCM = false, maxResultCount = MAX_RESULT_COUNT }) => {
  return ApiService.User.GetStaffs({
    filterText,
    maxResultCount,
    sorting: 'Name',
    isGetBSOFromCM: isGetBSOFromCM,
  })
}

const CheckList = ({ filterText = '', maxResultCount = 1000 } = {}) => {
  return ApiService.Checklist.GetAll({ filterText, maxResultCount, sorting: 'Name', isActive: true })
}

const OptionApi = {
  User,
  Staff,
  Centre,
  CaseManager,
  Client,
  Company,
  ServiceProvider,
  Specialist,
  ActiveSpecialist,
  Contractor,
  LookupConfig,
  FundingProvider,
  FundingAgreement,
  CheckList,
  Borrower,
  City,
}

export default OptionApi
