import { FilterSetting, NotificationData } from './type'
import { ApiService } from '@services/ApiService'
import _ from 'lodash'
import { action, makeAutoObservable } from 'mobx'

const DefaultFilter = {
  skip: 0,
  maxResultCount: 20,
}

class NotificationSystemStore {
  static storeName = 'NotificationSystemStore'
  constructor() {
    makeAutoObservable(this)
    this.filterSetting = this.getFilterFromLocalStorage()
  }

  filterSetting: FilterSetting = {
    notificationTypes: [],
  }
  refreshCount = 0
  refreshDropDownContent = 0
  listViewMode = true
  openDropdown = false
  filter = _.cloneDeep(DefaultFilter)
  isLoading = false
  loaded = false

  summary: {
    totalCount: number
    totalUnreadCount: number
  } = {
    totalCount: 0,
    totalUnreadCount: 0,
  }

  notifications: NotificationData[] = []

  getFilterFromLocalStorage = (): FilterSetting => {
    const filter = localStorage.getItem('NOTIFICATION_FILTER')

    if (filter) {
      try {
        const data = JSON.parse(filter) as FilterSetting
        if (data) {
          return data
        }
      } catch (error) {
        console.error('Error when parse filter', error)
      }
    }

    return {
      notificationTypes: [],
    }
  }

  updateFilterSetting = (data: FilterSetting) => {
    this.filterSetting = data
    localStorage.setItem('NOTIFICATION_FILTER', JSON.stringify(data))
  }

  setOpenCloseDropdown = async isOpen => {
    if (isOpen && !this.loaded) {
      await this.fetchNotifications()
      this.loaded = true
    }
    this.openDropdown = isOpen

    if (isOpen) {
      this.refreshDropDownContent++
    }
  }

  onChangeViewMode = () => {
    this.listViewMode = !this.listViewMode
  }

  refresh = async () => {
    this.fetchNotifications()
    this.getSummary()
  }

  fetchNotifications = async (filterData: any = {}) => {
    this.isLoading = true
    const data = { ...this.filter, ...filterData, ...this.filterSetting }
    try {
      const res = await ApiService.Notification.GetAll(data, {
        disableCache: true,
      })

      const newItems = []
      res.data.items.forEach(item => {
        const exist = this.notifications.find(i => i.recipientId === item.recipientId)
        if (!exist) {
          newItems.push(item)
        } else {
          const newItem = Object.assign({}, exist, item)
          newItems.push(newItem)
        }
        this.notifications = newItems
      })
    } catch (error) {
      console.error('Error when fetch notification', error)
    } finally {
      action(() => {
        this.isLoading = false
      })()
    }
  }

  fetchMoreUpdate = async (data: NotificationData) => {
    try {
      const res = await ApiService.Notification.SearchByObjectId(data.objectId)
      const items = res.data
      data.moreUpdates = items.filter(i => i.recipientId !== data.recipientId) ?? []
    } finally {
    }
  }

  markAsRead = async (id: string) => {
    try {
      await ApiService.Notification.MarkAsRead(id)
    } finally {
    }
  }

  markAsUnread = async (id: string) => {
    try {
      await ApiService.Notification.MarkAsUnread(id)
    } finally {
    }
  }

  getSummary = async () => {
    try {
      const res = await ApiService.Notification.GetSummary()
      this.summary = res.data
    } finally {
    }
  }

  markAllAsRead = async () => {
    try {
      await ApiService.Notification.MarkAllAsRead()

      this.notifications.forEach(item => {
        item.isRead = true
        if (item.moreUpdates) {
          item.moreUpdates.forEach(i => {
            i.isRead = true
          })
        }
      })
      this.summary.totalUnreadCount = 0
      this.refreshDropDownContent++
    } finally {
    }
  }
}

export default new NotificationSystemStore()
