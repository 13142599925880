import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import appStore from '@stores/appStore'
import { ApiService } from '@services/ApiService'

const RingCentral = observer(() => {
  useEffect(() => {
    if (!appStore.isRingCentralEmbeddable || !appStore.isAdminOrStaff) {
      return
    }
    // Dynamically load the RingCentral C2D script
    const c2dScript = document.createElement('script')
    c2dScript.src = 'https://unpkg.com/ringcentral-c2d@1.0.0/build/index.js'
    document.body.appendChild(c2dScript)

    // Function to inject the Embeddable script
    const injectEmbeddable = () => {
      const clientId = appStore.currentTenant?.settings['ICS.RingCentral.ClientId']
      const appServer = appStore.currentTenant?.settings['ICS.RingCentral.ServerUrl']

      const rcs = document.createElement('script')
      rcs.src = `https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=${clientId}&appServer=${appServer}`
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(rcs, firstScript)
    }

    // Ensure the C2D script is loaded before injecting the Embeddable script
    c2dScript.onload = injectEmbeddable
    c2dScript.onerror = () => {
      console.error('Failed to load RingCentral Embeddable script')
    }
    // Cleanup function to remove scripts when the component unmounts
    return () => {
      document.body.removeChild(c2dScript)
      // Note: Removing dynamically injected script tags might not revert their global side-effects.
    }
  }, [appStore.isRingCentralEmbeddable])

  useEffect(() => {
    if (!appStore.isRingCentralEmbeddable || !appStore.isAdminOrStaff) {
      return
    }

    const handleMessage = e => {
      const data = e.data
      if (data) {
        switch (data.type) {
          case 'rc-active-call-notify':
            // get call on active call updated event
            // console.log('rc-active-call-notify', data.call)
            break
          case 'rc-dialer-status-notify':
            // get dialer status from widget
            // console.log('rc-dialer-status-notify:', data.ready)
            break
          case 'rc-call-end-notify':
            // console.log('rc-call-end-notify', data.call)
            ApiService.RingCentral.CallResult({ ...data.call, ...window.RCMetaData })
              .then(result => {
                // console.log('API call successful:', result)
              })
              .catch(error => {
                console.error('API call failed:', error)
              })
              .finally(() => {
                delete window.RCMetaData
              })
            break
          default:
            break
        }
      }
    }
    window.addEventListener('message', handleMessage)
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [appStore.isRingCentralEmbeddable])

  return (
    <>
      <style>
        {`@media print {
          #rc-widget {
            display: none !important;
        }
      }`}
      </style>
    </>
  )
})

export default RingCentral
